import React from "react"
import { Link } from "gatsby"

let Nav = () => {
    return (
    <nav className="main-menu">
        <Link to="/">Home</Link>
        <Link to="/about/">About</Link>
        <Link to="/contact/">Contact</Link>
        <Link to="/import/">Import Testing</Link>
    </nav>
    )
}

export default Nav