export default [ {
    id: 1,
    text: "Use React functional components",
    completed: true
},
{
    id: 2,
    text: "Make the site responsive",
    completed: true
},
{
    id: 3,
    text: "Add a contact form to the site",
    completed: true
},
{
    id: 4,
    text: "Import images through Gatsby",
    completed: true
},
{
    id: 5,
    text: "Connect to Github and auto-deploy commits made to the master branch",
    completed: true
},
{
    id: 6,
    text: "Learn more about GraphQL",
    completed: false
}
]