let cheesecakes = [
    {
    id: 1,
    imgURL: "https://davor.blog/wp-content/uploads/2019/10/p_20191021_184301_vhdr_on2886796405953944532.jpg",
    title: "Gelato Bar",
    description: "Fantastic cheesecake in Belgrade's Gelato bar!",
    link: "https://davor.blog/2019/10/21/cheesecake-belgrade/"
    },
    {
    id: 2,
    imgURL: "https://davor.blog/wp-content/uploads/2019/10/p_20191005_204219_vhdr_on38196304961472334.jpg",
    title: "District",
    description: "Amazing cheesecake in Novi Sad's District!",
    link: "https://davor.blog/2019/10/06/cheesecake-novi-sad-2/"
    },
    {
    id: 3,
    imgURL: "https://davor.blog/wp-content/uploads/2019/06/p_20190625_155745_vhdr_on9082979681210905870.jpg",
    title: "Camelot",
    description: "Superb cheesecake in Novi Sad's Camelot!",
    link: "https://davor.blog/2019/06/25/cheesecake-novi-sad/"
    }

]

export default cheesecakes