import React from "react"
import Layout from "../components/layout"
import Head from "../components/Head"
import Nav from "../components/Nav"
import Header from "../components/Header"
import Main from "../components/Main"
import Block from "../components/Block"
import Footer from "../components/Footer"
import cheesecakes from "../data/Cheesecakes"

let myCheesecakes = cheesecakes.map((cheesecake) => {
    /*
    Simple way to solve the array mapping (but needs modifying the Block component to remove "blocks" from props)
    
    return (
    <Block key={cheesecake.id} link={cheesecake.link} imgURL={cheesecake.imgURL} title={cheesecake.title} paragraph={cheesecake.description}/>
    )*/

    return (
        <Block key={cheesecake.id} blocks={cheesecake} />
    )
})

export default () => (
        <Layout>
            <Head />
            <Nav />
            <Header title="Building with Gatsby"/>
            <Main />
            <hr />
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
            {myCheesecakes}
            </div>
            <Footer />
        </Layout>
    )
