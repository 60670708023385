import React from "react"
import profile from "../images/profile.jpg"
import logo from "../images/gatsby-logo.png"
import TodoItem from "./todoItem"
import todoList from "./todoList"


let Main = () => {

    const todo = todoList.map((item) => {
        return <TodoItem key={todoList.id} todoList={item} />
     }
    )
    
    return (
        <main className="main">
            <a href="https://gatsbyjs.org" target="_blank" rel="noopener noreferrer"><img id="profile" src={logo} alt="Gatsbyjs Logo" /></a>
            <div style={{maxWidth: "400px"}}>
                <h2 style={{display: "flex", justifyContent: "center"}}>What's this site about?</h2>
                <p style={{textAlign: "center"}}>When I first saw <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby</a>, I immediately knew I wanted to build sites with it. To be able to do that, there are quite a few bunch of technologies I will need to learn first. I'm using this site for practicing my new skills.</p>
                <div className="todoList">
                    {todo}
                </div>
            </div>
            <a href="https://davor.blog" target="_blank" rel="noopener noreferrer"><img id="profile" src={profile} alt="Davor Altman profile" /></a>
        </main>
    )
}

export default Main