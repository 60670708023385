import React from "react"

let TodoItem = (props) => {
    return(
        <div className="todoItem">
            <label style={{color: props.todoList.completed ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 1)"}}>
            <input type="checkbox" checked={props.todoList.completed} />
            {props.todoList.text}
            </label>
        </div>
    )
}



export default TodoItem