import React from "react"

let Footer = () => {
    return(
        <footer className="footer">
            <p style={{fontSize: 18}}>Built with <a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">Gatsby</a> on <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">Netlify</a></p>
        </footer>
    )
}

export default Footer