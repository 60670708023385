import React from "react"


let Block = (props) => {
    return(
        <div className="block">
                <a href={props.blocks.link} target="_blank" rel="noopener noreferrer"><img style={{maxWidth: "375px", maxHeight: "375px", alignSelf: "center"}} src={props.blocks.imgURL} alt="One of many cheesecakes I ate!" /></a>
                <h2>{props.blocks.title}</h2>
                <p>{props.blocks.paragraph}</p>
        </div>
    )
}


export default Block